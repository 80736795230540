<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <form class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Confirmar</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline">
          <div class="column is-12 has-text-centered">
            ¿Estas seguro que deseas eliminar esta imagen?
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-danger"
          type="reset"
          @click="close"
        >
          Cancelar
        </button>
        <button
          class="button button-givu"
          type="button"
          @click="action(imageId)"
        >
          Guardar
        </button>
      </footer>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirmDelete',
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    action: {
      type: Function,
      default: () => {}
    },
    imageId: {
      type: Number
    }
  }
}
</script>
